<script>
import { debounce, reduce } from "lodash";
import moment from "moment";
import { mapGetters } from "vuex";

const tipoEnum = {
  COLABORADORES: "colaboradores",
  SERVICOS_TERCEIROS: "servicos-terceiros",
};
const tipoRelatorioEnum = {
  COLABORADORES: "justificativa-colaboradores-por-projetos",
  SERVICOS_TERCEIROS: "justificativa-terceiros-por-projetos",
};
const tipoRelatorioImportEnum = {
  COLABORADORES: "colaboradores",
  SERVICOS_TERCEIROS: "terceiros",
};

export default {
  components: {
    FormModal: () => import("@/components/form-modal.vue"),
    GModal: () => import("@/components/g-modal.vue"),
    MonthlyFilter: () => import("@/components/monthly-filter.vue"),
    QueueDialog: () => import("@/components/queue-dialog.vue"),
    ServicoSelect: () => import("@/components/servico-select.vue"),
    TableV: () => import("@/components/table-v.vue"),
  },
  data: function () {
    return {
      debouncedLoad: () => {},
      projetos: [],
      /** @type {('colaboradores' | 'servicos-terceiros')} */
      tipoSelecionado: "colaboradores",
      tipos: [
        { label: "Colaboradores", id: tipoEnum.COLABORADORES },
        { label: "Serviços terceiros", id: tipoEnum.SERVICOS_TERCEIROS },
      ],
      isVisibleUploadFile: false,
      uploadFileData: {},
      tipoEnum: tipoEnum,
      isVisibleQueueDialog: false,
      selectedNotas: [],
      modalNotas: false,
      selectedRow: {},
      modalJustificativa: false,
      modalJustificativaAgrupamento: false,
    };
  },
  created() {
    this.debouncedLoad = debounce(this.doLoad, 250);
  },
  watch: {
    clientId: function () {
      this.doLoad();
    },
  },
  computed: {
    ...mapGetters(["clientId", "servico", "intervaloCompetencia"]),
    cols: function () {
      return [
        {
          text: "Matrícula",
          value: "matricula",
          show: tipoEnum.COLABORADORES,
        },
        {
          text: "Nome",
          value: "nome",
          show: tipoEnum.COLABORADORES,
        },
        {
          text: "CPF",
          value: "cpf",
          show: tipoEnum.COLABORADORES,
          format: this.$options.filters.toCPF,
          nowrap: true,
        },
        {
          text: "Cargo",
          value: "cargo",
          show: tipoEnum.COLABORADORES,
        },
        {
          text: "Escolaridade",
          value: "escolaridade",
          show: tipoEnum.COLABORADORES,
        },
        {
          text: "Fornecedor",
          value: "razao_social",
          show: tipoEnum.SERVICOS_TERCEIROS,
        },
        {
          text: "CNPJ",
          value: "cnpj",
          show: tipoEnum.SERVICOS_TERCEIROS,
          format: this.$options.filters.toCNPJ,
        },
        {
          text: "Valor",
          value: "valor",
          format: this.$options.filters.toCurrency,
          nowrap: true,
        },
        {
          text: "Justificativa",
          value: "justificativa",
        },
        {
          text: "",
          value: "notas",
          show: tipoEnum.SERVICOS_TERCEIROS,
        },
        {
          text: "",
          value: "projeto_id",
          show: true,
        },
      ].filter(({ show }) => !show || show === this.tipoSelecionado);
    },
    pageTitle: function () {
      return `Justificativas de ${
        this.tipoSelecionado === tipoEnum.COLABORADORES
          ? "Colaboradores"
          : "Serviços Terceiros"
      } por projeto`;
    },
    resourceUrl() {
      return `/v1/projetos/${this.clientId}/justificativas/${this.tipoSelecionado}`;
    },
    relatorioXlsx: function () {
      let rows = this.projetos.reduce(
        (prev, { colaboradores, total, projeto }) => {
          let content = (Array.isArray(colaboradores) ? colaboradores : []).map(
            (colaborador) => {
              const row = this.cols.map(
                ({ value }) => colaborador[value] || ""
              );
              row.unshift(projeto.titulo || "");
              return row;
            }
          );
          let totalRow = this.cols.map(({ value }) => total[value] || "");
          totalRow.splice(1, 0, "");
          return [...prev, ...content, totalRow];
        },
        []
      );

      let styles = rows
        .reduce(
          (prev, row, rowIndex) => {
            const refs = [];

            if ([1, 2].includes(row.length)) {
              refs.push(rowIndex + 2);
            }

            row.forEach((cell, cellIndex) => {
              if (cell === "Total") {
                refs.push(rowIndex + 2);
              }

              if (typeof cell === "string" && cell.includes("R$ ")) {
                rows[rowIndex][cellIndex] = cell.replace("R$ ", "");
              }
            });

            return [...prev, ...refs];
          },
          [1]
        )
        .reduce(
          (acc, key) => ({
            ...acc,
            [key]: {
              font: {
                bold: true,
              },
            },
          }),
          {}
        );

      const columns = [
        {
          text: "Projeto",
          value: "projeto",
        },
        ...this.cols,
      ];

      return [
        rows,
        {
          columns,
          periodoCompetencia: this.competenciaReport,
          servico: this.servico,
          styles,
        },
      ];
    },
    competenciaReport: function () {
      let mIni = moment(this.intervaloCompetencia[0]);
      let mFim = moment(this.intervaloCompetencia[1]);
      return { ini: mIni.format("YYYY-MM"), fim: mFim.format("YYYY-MM") };
    },
    headerStyle: function () {
      return this.cols.map((h) => {
        if (h.value == "total") {
          return;
        }

        h.class = `vuetify-table-header${
          this.getClient().isClient ? "-cli" : ""
        }`;
        return h;
      });
    },
    uploadCols() {
      return [
        {
          key: "files",
          name: "Arquivo(s)",
          type: this.$fieldTypes.FILE,
          accept: ".xlsx",
          multiple: false,
        },
      ];
    },
    justificativaCols() {
      return [
        {
          key: "justificativa",
          name: "Justificativa",
          type: this.$fieldTypes.TEXTAREA,
          rules: [{ rule: "max", params: { size: 4000 } }],
        },
      ];
    },
    justificativaAgrupamentoCols() {
      return [
        {
          key: "agrupamento_justificativa",
          name: "Justificativa",
          type: this.$fieldTypes.TEXTAREA,
          rules: [{ rule: "max", params: { size: 4000 } }],
        },
      ];
    },
    notasCols() {
      return [
        {
          name: "Descrição",
          key: "descricao",
        },
        {
          name: "Valor",
          key: "valor",
          type: this.$fieldTypes.MONEY,
        },
      ];
    },
  },
  methods: {
    doLoad() {
      this.projetos = [];
      this.apiResource(this.resourceUrl)
        .get({
          query: `competenciaIni=${this.intervaloCompetencia[0]}&competenciaFim=${this.intervaloCompetencia[1]}&trabalho=${this.servico}`,
        })
        .then((response) => {
          this.projetos = response.map((row) => {
            row.colaboradores ? row.colaboradores = row.colaboradores.map((col) => ({ ...col,  projeto_id: row.projeto.id })) : row.terceiros = row.terceiros.map((col) => ({ ...col,  projeto_id: row.projeto.id }));
            return {
              ...row,
              total: reduce(
                row.colaboradores || row.terceiros || [],
                (rowA, rowB) => {
                  rowA.valor += rowB.valor ? rowB.valor * 1 : 0;
                  return rowA;
                },
                { nome: "Total", valor: 0 }
              ),
            }
          });
        });
    },
    saveData: function (formData = {}) {
      const { justificativa_id, justificativa, id, projeto_id } = formData;
      const isColaboradores =  this.tipoSelecionado === tipoEnum.COLABORADORES ? true : false;

      return {
        'model_id': id,
        'model_type': isColaboradores ? "funcionarios" : "terceiros",
        'projeto_id': projeto_id,
        'justificativa': justificativa,
        'justificativa_id': justificativa_id,
      }
    },
    async doSave(formData = {}, close) {
      try {
        const saveData = this.saveData(formData);
        const url = `/v1/projetos/${this.clientId}/justificativas`;
        await this.apiResource(url).save({ ...saveData }, saveData.justificativa_id);

        if (close) {
          close();
        }

        this.doLoad();
      } catch (error) {
        this.notify(error);
      }
    },
    saveDataAgrupamento: function (formData = {}) {
      const { agrupamento_justificativa_id, agrupamento_justificativa, id, projeto_id } = formData;
      const isColaboradores =  this.tipoSelecionado === tipoEnum.COLABORADORES ? true : false;
      const projeto = this.projetos.filter((p) => p.projeto.id == projeto_id)[0];

      return {
        'model_id': id,
        'model_type': isColaboradores ? "funcionarios" : "terceiros",
        'projeto_id': projeto.projeto.agrupamento_id,
        'justificativa': agrupamento_justificativa,
        'agrupamento_justificativa': agrupamento_justificativa,
        'agrupamento_justificativa_id': agrupamento_justificativa_id,
      }
    },
    async doSaveJustificativaAgrupamento(formData = {}, close) {
      try {
        const saveData = this.saveDataAgrupamento(formData);
        const url = `/v1/projetos/${this.clientId}/justificativas`;
        await this.apiResource(url).save({ ...saveData }, saveData.agrupamento_justificativa_id);
        if (close) {
          close();
        }

        this.doLoad();
      } catch (error) {
        this.notify(error);
      }
    },
    importXlsx() {
      this.isVisibleUploadFile = true;
    },
    relatorioJustificativaImportRequest() {
      const uploadedFile = this.$store.getters.uploadedFile;
      const tipoUpload =
        this.tipoSelecionado == tipoEnum.COLABORADORES
          ? tipoRelatorioImportEnum.COLABORADORES
          : tipoRelatorioImportEnum.SERVICOS_TERCEIROS;
      let formData = new FormData();
      formData.append("arquivo", uploadedFile);
      formData.append("tipo", tipoUpload);
      return formData;
    },
    doImportXls() {
      const uploadRequest = this.relatorioJustificativaImportRequest();
      this.apiResource(`v1/projetos/${this.clientId}/justificativas/importar`)
        .save(uploadRequest)
        .then((result) => {
          this.showNotification(result);
          this.isVisibleUploadFile = false;
        });
    },
    doEdit(row = {}) {
      this.selectedRow = row;
      this.modalJustificativa = true;
    },
    doEditAgrupamento(row = {}) {
      this.selectedRow = row;
      this.modalJustificativaAgrupamento = true;
    },
    showNotification(result) {
      const message = !result.error
        ? "Relatório importado com sucesso"
        : "Erro ao importar o relatório";
      this.$notify({
        group: "geral",
        type: !result.error ? "success" : "error",
        text: message,
        title: message,
        duration: 5000,
      });
    },
    relatorioJustificativaRequest() {
      const { ini, fim } = this.competenciaReport;
      const tipoRelatorio =
        this.tipoSelecionado == tipoEnum.COLABORADORES
          ? tipoRelatorioEnum.COLABORADORES
          : tipoRelatorioEnum.SERVICOS_TERCEIROS;
      const trabalho = this.servico;
      return {
        tipoRelatorio,
        competenciaIni: ini,
        competenciaFim: fim,
        trabalho,
      };
    },
    exportacaoRelatorio() {
      const relatorioRequest = this.relatorioJustificativaRequest();
      this.apiResource(`v1/clientes/${this.clientId}/exportacao`)
        .save(relatorioRequest)
        .then((result) => {
          if (!result.error) this.isVisibleQueueDialog = true;
        });
    },
    openNotasModal(notas = []) {
      this.selectedNotas = notas;
      this.modalNotas = true;
    },
  },
};
</script>

<template>
  <v-card class="teros-elevation" :color="tableColor">
    <v-card-title class="px-5 pb-0 text-uppercase v-title-cadastro">
      {{ pageTitle }}
    </v-card-title>
    <v-card-text class="d-flex flex-row items-center">
      <div class="d-flex pa-2 pl-0 justify-between align-center">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
           <v-btn depressed v-on="on" v-bind="attrs" style="margin-top: -0.2rem; background: none; font-size: 0.83rem; font-weight: 400;">
            <v-icon left style="font-size: 1.4rem">mdi-file-chart-outline</v-icon>Relatório
           </v-btn>
          </template>
          <v-list style="padding: 1rem;">
            <v-list-item-content>
              <v-list-item-title
                class="table-v-action-button mr-3" @click="exportacaoRelatorio()"
                style=" font-size: 0.81rem; font-weight: 400; cursor: pointer;">
                <v-icon>mdi-file-export-outline</v-icon>Exportação
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-content>
              <v-list-item-title
                class="table-v-action-button mr-3" @click="importXlsx()"
                style=" font-size: 0.81rem; font-weight: 400; cursor: pointer;">
                <v-icon>mdi-file-import-outline</v-icon>Importação
              </v-list-item-title>
            </v-list-item-content>
          </v-list>
        </v-menu>
        <v-select
          v-model="tipoSelecionado"
          prependIcon="mdi-format-list-bulleted-type"
          class="mr-3 pa-0 mb-0"
          hide-details
          :items="tipos"
          itemText="label"
          itemValue="id"
          prefix="Tipo:"
          style="margin-top: -6px; max-width: 270px; "
          @change="debouncedLoad()"
        >
          <template v-slot:item="{ item }">
            <v-list-item-content>
              <v-list-item-title style="font-size: 0.81rem; font-weight: 500;">
                {{ item.label }}
              </v-list-item-title>
            </v-list-item-content>
          </template>
        </v-select>
        <MonthlyFilter
          style="min-width: 270px"
          @change="debouncedLoad()"
        ></MonthlyFilter>
        <ServicoSelect
          class="pl-2 mt-0"
          style="max-width: 255px"
          @change="debouncedLoad()"
        ></ServicoSelect>
      </div>
    </v-card-text>
    <div
      v-if="!projetos.length"
      class="font-weight-bold white d-block pa-3 text-center"
    >
      <span> Nenhum projeto retornado para os filtros selecionados. </span>
    </div>
    <template v-for="(proj, idx) in projetos">
      <span
        class="font-weight-bold d-block pl-4 pb-4"
        :class="{ 'pt-6': idx != 0 }"
        :key="idx + 'span'"
      >
        <p class="mb-1">Projeto: {{ proj.projeto.codigo }} {{ (proj.projeto.codigo && proj.projeto.titulo) ? " — " : ""}} {{ proj.projeto.titulo }} - {{ proj.projeto.anoBase }}</p>
        <span v-if="proj.projeto.agrupamento_titulo">Agrupamento: {{ proj.projeto.agrupamento_codigo }} {{ (proj.projeto.agrupamento_codigo && proj.projeto.agrupamento_titulo) ? " — " : ""}} {{ proj.projeto.agrupamento_titulo }} - {{ proj.projeto.anoBase }} </span>
      </span>
      <v-data-table
        :key="idx"
        data-app
        :items="proj.colaboradores || proj.terceiros"
        :headers="headerStyle"
        :footer-props="{
          'items-per-page-options': [-1],
        }"
        hide-default-footer
      >
        <template v-slot:item="{ item, headers }">
          <tr class="table-v-tr" style="cursor: default">
            <td
              v-for="col in headers"
              v-bind:key="col.value"
              :class="{ nowrap: col.nowrap }"
              :style="{ 'text-align': col.textAlign }"
            >
              <div
                v-if="col.value === 'nome' || col.value === 'razao_social'"
                class="text-truncate"
                style="min-width: 300px">
                <span v-if="!item.precisa_justificativa_para_agrupamento">{{ item.razao_social || item.nome }}</span>
                <v-menu
                  v-else
                  bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ item.razao_social || item.nome }}
                      <v-icon
                        :style="{ color: 'red' }">mdi-circle-medium
                      </v-icon>
                    </span>
                  </template>
                  <v-list>
                    <v-list-item
                      class="cursor-pointer"
                      @click="doEditAgrupamento(item)">
                      <v-list-item-title
                        style="font-size: 13px;"
                      >
                        Preencher justificativa para agrupamento
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
              <v-btn
                v-else-if="col.value === 'notas'"
                color="primary"
                icon
                @click="openNotasModal(item.notas)"
              >
                <v-icon large>mdi-card-search</v-icon>
              </v-btn>
              <div
                v-else-if="col.value === 'justificativa'"
                class="cursor-pointer text-truncate"
                style="max-width: 300px"
                :title="item.justificativa"
                @click="doEdit(item)"
              >
                {{ item.justificativa || "Sem justificativa." }}
              </div>
              <template v-else>
                {{ col.format ? col.format(item[col.value]) : item[col.value] }}
              </template>
            </td>
          </tr>
        </template>
        <template v-slot:[`body.append`]="append">
          <tr class="table-v-tr">
            <th
              style="cursor: default; font-size: 13px !important"
              v-for="col in append.headers"
              v-bind:key="col.value"
              :class="{ nowrap: col.nowrap }"
              :style="{ 'text-align': col.textAlign }"
            >
              {{
                col.format
                  ? col.format(proj.total[col.value])
                  : proj.total[col.value]
              }}
            </th>
          </tr>
        </template>
      </v-data-table>
    </template>

    <FormModal
      title="Editar justificativa"
      :cols="justificativaCols"
      :opened.sync="modalJustificativa"
      :value.sync="selectedRow"
      @save="doSave"
    ></FormModal>

    <FormModal
      title="Editar justificativa agrupamento"
      :cols="justificativaAgrupamentoCols"
      :opened.sync="modalJustificativaAgrupamento"
      :value.sync="selectedRow"
      @save="doSaveJustificativaAgrupamento"
    ></FormModal>

    <FormModal
      title="Importação justificativa"
      :cols="uploadCols"
      :opened.sync="isVisibleUploadFile"
      :value.sync="uploadFileData"
      @save="doImportXls()"
    ></FormModal>

    <GModal
      v-model="modalNotas"
      class="notas-g-modal"
      noPadding
      title="Descrições da nota fiscal"
    >
      <TableV
        class="notas-table-v"
        :cols="notasCols"
        disablePagination
        height="auto"
        :rows="selectedNotas"
      ></TableV>
    </GModal>

    <QueueDialog
      :opened.sync="isVisibleQueueDialog"
      title="Exportação justificativas"
      appendTitleIcon="mdi-file-chart-outline"
    ></QueueDialog>
  </v-card>
</template>

<style lang="scss" scoped>
.notas-table-v {
  min-width: 500px;

  .v-data-table__wrapper {
    height: unset !important;
  }
}
</style>
